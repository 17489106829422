import { Button, Card, CardActions, CardContent, CardHeader } from "@mui/material"
import { KcContextBase, KcProps, useKcMessage } from "keycloakify"
import { memo } from "react"

export const Error = memo(({ kcContext, ...props }: { kcContext: KcContextBase.Error; } & KcProps) => {
  const { message, client } = kcContext;
  const { msg: kcMessage } = useKcMessage();

  return <Card id="kc-error-message">
    <CardHeader title={kcMessage("errorTitle")} />
    <CardContent className="instruction">
      {message.summary}
    </CardContent>
    <CardActions sx={{ justifyContent: "center" }}>
      {
        !!client && !!client.baseUrl &&
        <Button fullWidth id="backToApplication" href={client.baseUrl}>
          {kcMessage("backToApplication")}
        </Button>
      }
    </CardActions>
  </Card>
})