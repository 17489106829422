import { LayoutConstant } from "./constant/layout.constant";
import { Button, Grid } from "@mui/material";
import { I18nMenu } from "./I18nMenu";
import { KeycloakContext } from "@type/Keycloak";

export const Header = ({ kcContext }: { kcContext: KeycloakContext }) => {
  const { realm } = kcContext;

  return <Grid container flexWrap="nowrap" alignItems="center" py={1}>
    <Grid item>
      <Button href={LayoutConstant.header.websiteUrl}>
        <img height={40} width={120} src={LayoutConstant.header.logo} alt="Otus logo" />
      </Button>
    </Grid>

    <Grid item container flex="1" justifyContent="end">
      { realm.internationalizationEnabled && <I18nMenu kcContext={kcContext} /> }
    </Grid>
  </Grid>;
}