import { createTheme } from '@mui/material/styles';

import './styles/index.scss';

import { paletteOptions } from './Palette';
import { getTypographyOptions } from './Typography';

const AppTheme = createTheme({
  palette: paletteOptions
});

getTypographyOptions(AppTheme);

export default AppTheme;