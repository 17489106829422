import { Divider, Grid } from "@mui/material"
import { KcContextBase } from "keycloakify";
import { ActionButton } from "@atom/ActionButton";

export const LoginSocialProviders = (kcContext: KcContextBase.Login) => {
  const { realm, social } = kcContext;

  return realm.password && social.providers
    ? <>
      <Grid item mt={2} mb={1}>
        <Divider />
      </Grid>

      <Grid item container spacing={2} justifyContent="center">
        {
          social.providers.map((provider: any) => <Grid item key={provider.providerId}>
              <ActionButton sx={{minWidth: "unset", width: 40, height: 40, borderRadius: 50}} href={provider.loginUrl}>
                <i style={{ fontSize: 24 }} className={`fab ${ provider.iconClasses.split(" ")[1] }`} />
              </ActionButton>
            </Grid>
          )
        }
      </Grid>
    </>
    : <></>
}