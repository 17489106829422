import { PaletteOptions } from '@mui/material';

export const paletteOptions: PaletteOptions = {
  primary: {
    main: '#2ABEA4',
    contrastText: '#FFFFFF'
  },

  secondary: {
    main: '#76B7F3',
    contrastText: '#FFFFFF'
  },

  text: {
    primary: '#505050',
    secondary: '#7A7A7A'
  }
};
