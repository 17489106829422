import axios, {AxiosInstance, AxiosRequestConfig} from "axios";

export class HttpRestService<T> {

    public client: AxiosInstance

    constructor(baseURL: string) {
        this.client = axios.create({
            baseURL,
            responseType: "json"
        })
    }

    async get(path?: string, options?: AxiosRequestConfig): Promise<T[]> {
        return (await this.client.get(path || '/', options))?.data;
    }

    async post(path?:string, options?: AxiosRequestConfig): Promise<T> {
        return (await this.client.post(path || '/', options))?.data;
    }

    async put(path?: string, options?: AxiosRequestConfig): Promise<T> {
        return (await this.client.put(path || '/', options)).data;
    }

    async delete(path?: string, options?: AxiosRequestConfig): Promise<void> {
        return this.client.delete(path || '/', options);
    }
}