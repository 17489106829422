import { Grid, Typography, Box, useMediaQuery, useTheme } from "@mui/material";
import { KcLanguageTag, useKcLanguageTag } from "keycloakify";

import { LayoutConstant } from './constant/layout.constant';
import { Intro } from "@layout/Intro";
import { Header } from "@layout/Header";
import { ReactNode } from "react";
import { KeycloakContext } from "@type/Keycloak";
import { SetUserProject } from "shared/service/set-user-project.service";

export const Layout = ({ kcContext, children }: { kcContext: KeycloakContext, children: ReactNode }) => {
  const theme = useTheme();
  const mqLg = useMediaQuery(theme.breakpoints.down('lg'));

  const AllowedLanguages = typeof Object.keys(LayoutConstant.content.title);
  const { kcLanguageTag } = useKcLanguageTag() as { kcLanguageTag: Extract<KcLanguageTag, typeof AllowedLanguages>, setKcLanguageTag: () => void };

  const redirectUri = (new URLSearchParams(window.location.search)).get("redirect_uri")
        || localStorage.getItem('redirect_uri');
  
  console.log(redirectUri);

  if (redirectUri) {
    new SetUserProject().execute(redirectUri).then();
  }

  return (
    <Box px={2} display="flex" flexDirection="column" flexWrap="nowrap" gap={4} flex="1">
      <Header kcContext={kcContext} />

      <Grid container direction={ mqLg ? "column" : "row" } spacing={5} sx={{ flex: "1 0 auto" }}>
        <Intro />

        <Grid item container md="auto" lg={6} direction="column" spacing={1} justifyContent="center" alignItems="center">
          <Grid item xs="auto" width="min(100%, 480px)">
            { children }
          </Grid>

          <Grid item textAlign={"center"}>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {
                Object.keys(LayoutConstant.content.title).includes(kcLanguageTag)
                  ? LayoutConstant.content.copyright[kcLanguageTag]
                  : LayoutConstant.content.copyright.en
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
