import { Theme } from '@mui/material';

export function getTypographyOptions(theme: Theme) {
  theme.typography.h1 = {
    fontSize: '3rem',
    fontFamily: "'Readex Pro', sans-serif",
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem'
    }
  };

  theme.typography.h3 = {
    fontSize: '3rem',
    fontFamily: 'Roboto',
    fontWeight: 300,
    [theme.breakpoints.down('md')]: {
      fontSize: '2.5rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.75rem'
    }
  };

  theme.typography.h6 = {
    fontSize: '1.5rem',
    fontFamily: 'Roboto',
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      fontSize: '1.25rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.25rem'
    }
  };
};