import { GatewayRestService } from "shared/resource/GatewayRestService";

export class SetUserProject {
    private gatewayRestService: GatewayRestService
    
    constructor() {
        this.gatewayRestService = new GatewayRestService();
    }

    async execute(redirectUri: string) {
        const preloads = (await this.gatewayRestService.getAccountPreload(redirectUri));
        if (preloads && preloads.length) {
            console.log(preloads[0]);
            localStorage.setItem('projectId', preloads[0].project.id);
            localStorage.setItem('projectName', preloads[0].project.name);
        }
        
    }
}