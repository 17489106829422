import { Grid, Link, Typography } from "@mui/material"
import { KcContextBase, useKcMessage } from "keycloakify";

export const LoginHelpersMenu = (kcContext: KcContextBase.Login) => {
  const { realm, login, url, usernameEditDisabled } = kcContext;
  const { msg: kcMessage } = useKcMessage();

  return <Grid item container alignItems="center">
    <Grid item xs={6} container alignItems="center" wrap="nowrap" id="kc-form-options">
      {
        realm.rememberMe && !usernameEditDisabled && <div className="checkbox">
          <label style={{ display: "flex", gap: 8 }}>
            <input
              tabIndex={3}
              id="rememberMe"
              name="rememberMe"
              type="checkbox"
              {...(login.rememberMe ? { "checked": true } : {})}
            />
            <Typography variant="body2">
              {kcMessage("rememberMe")}
            </Typography>
          </label>
        </div>
      }
    </Grid>

    <Grid item xs={6} container alignItems="center" justifyContent="end">
      {
        realm.resetPasswordAllowed &&
        <Link tabIndex={4} variant="body2" href={url.loginResetCredentialsUrl}>
          {kcMessage("doForgotPassword")}
        </Link>
      }
    </Grid>
  </Grid>
}