import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material"
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { useState } from "react"

export const PasswordInput = (props: TextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);

  return <TextField
    fullWidth
    label={props.label}
    size="small"
    id={props.name}
    name={props.name}
    type={showPassword ? 'text' : 'password'}
    {...props}
    InputProps={{
      endAdornment: <InputAdornment position="end">
        <IconButton onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </InputAdornment>
    }}
  />
}