export const LayoutConstant = {
  header: {
    logo: 'https://site.otus-solutions.com.br/wp-content/uploads/2021/01/cropped-logo.png',
    websiteUrl: 'https://otus-solutions.com.br/'
  },

  content: {
    title: {
      'pt-BR': 'Soluções em gestão da informação',
      'en': 'Information Management Solutions'
    },
    subtitle: {
      'pt-BR': 'Seus dados sempre acessíveis, quando e onde precisar.',
      'en': 'Your data is always accessible, whenever and wherever you need it.'
    },
    subtitle1: {
      'pt-BR': 'Conheça nossas soluções em gerenciamento e armazenamento de dados clicando',
      'en': 'Discover our data management and storage solutions by clicking'
    },
    clickHere: {
      'pt-BR': 'aqui',
      'en': 'here'
    },
    copyright: {
      'pt-BR': '© 2021 todos os direitos reservados',
      'en': '© 2021 all rights reserved'
    }
  }
}