import { Button, Card, CardActions, CardContent, CardHeader, Link, Typography } from "@mui/material";
import { KcContextBase, KcProps, useKcMessage } from "keycloakify";
import { memo } from "react";

export const VerifyEmail = memo(({ kcContext, ...props }: { kcContext: KcContextBase.LoginVerifyEmail; } & KcProps) => {
  const { msg: kcMessage } = useKcMessage();
  const { url } = kcContext;

  return <Card>
    <CardHeader title={kcMessage("emailVerifyTitle")} />

    <CardContent>
      <Typography className="instruction">
        {kcMessage("emailVerifyInstruction1")}
      </Typography>

      <br />

      <Typography className="instruction">
        {kcMessage("emailVerifyInstruction2")}

        &nbsp;

        <Link href={url.loginAction}>
          {kcMessage("doClickHere")}
        </Link>

        &nbsp;

        {kcMessage("emailVerifyInstruction3")}
      </Typography>      
    </CardContent>

    <CardActions>
      <Button fullWidth id="loginRestartLink" href={url.loginRestartFlowUrl}>
        {kcMessage("backToLogin")}
      </Button>
    </CardActions>
  </Card>
})