import ReactDOM from 'react-dom';
import { KeycloakApp } from "@keycloak/KeycloakApp";
import AppTheme from '@theme/AppTheme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { KeycloakContext } from '@keycloak/KeycloakContext';

ReactDOM.render(
  <ThemeProvider theme={ AppTheme }>
    <CssBaseline />
    <KeycloakApp kcContext={ KeycloakContext } />
  </ThemeProvider>,
  document.getElementById("root")
);
