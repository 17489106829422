import { LayoutConstant } from "./constant/layout.constant";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import AuthBackgroundImage from "@asset/otus-background.png";
import { KcLanguageTag, useKcLanguageTag } from "keycloakify";

export const Intro = () => {
  const theme = useTheme();
  const mqLg = useMediaQuery(theme.breakpoints.down('lg'));

  const { kcLanguageTag } = useKcLanguageTag() as { kcLanguageTag: Extract<KcLanguageTag, "pt-BR" | "en">, setKcLanguageTag: () => void };

  return <Grid item container direction="column" md="auto" lg={6} justifyContent="space-between">
    <Grid item container direction="column">
      <Grid item>
        <Typography color="black" variant="h1" textAlign="start">
          {
            Object.keys(LayoutConstant.content.title).includes(kcLanguageTag)
              ? LayoutConstant.content.title[kcLanguageTag]
              : LayoutConstant.content.title.en
          }
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ color: "" }} variant="h6" textAlign={"start"}>
          {
            Object.keys(LayoutConstant.content.subtitle).includes(kcLanguageTag)
              ? LayoutConstant.content.subtitle[kcLanguageTag]
              : LayoutConstant.content.subtitle.en
          }
        </Typography>
      </Grid>
      <Grid item direction="row">
        <Typography sx={{ color: "" }} variant="body2" textAlign={"start"}>
          {
            Object.keys(LayoutConstant.content.subtitle1).includes(kcLanguageTag)
                ? LayoutConstant.content.subtitle1[kcLanguageTag]
                : LayoutConstant.content.subtitle1.en
          }
          &nbsp;
          <a style={{textDecoration: 'none', color: "#2ABEA4"}} target="_blank"  href={LayoutConstant.header.websiteUrl}>
            {
              Object.keys(LayoutConstant.content.clickHere).includes(kcLanguageTag)
                  ? LayoutConstant.content.clickHere[kcLanguageTag]
                  : LayoutConstant.content.clickHere.en
            }
          </a>
          .
        </Typography>
      </Grid>
    </Grid>
    <Grid item>
      <img
        className={`authorization-background ${mqLg ? 'absolute' : ''}`}
        src={AuthBackgroundImage}
        alt="Otus owl meeting"
      />
    </Grid>
  </Grid>;
}