import {memo, useEffect, useState} from "react";
import {KcContextBase, KcProps, useKcLanguageTag, useKcMessage, } from "keycloakify";
import { Card, CardActions, CardContent, CardHeader, Grid, Link} from "@mui/material";
import { useCssAndCx } from "tss-react";
import { ActionButton } from "@atom/ActionButton";
import { TextInput } from "@molecule/form/TextInput";
import { PasswordInput } from "@molecule/form/PasswordInput";
import { LoginSocialProviders } from "./LoginSocialProviders";
import { LoginHelpersMenu } from "./LoginHelpersMenu";

export const Login = memo(({kcContext, ...props}: { kcContext: KcContextBase.Login } & KcProps) => {
  const {
    realm,
    url,
    login,
    registrationDisabled,
    messagesPerField,
  } = kcContext;

  const { msg: kcMessage } = useKcMessage();
  const { kcLanguageTag } = useKcLanguageTag();

  const {cx} = useCssAndCx();

  let [usernameErrors, setUsernameErrors] = useState("")
  let [passwordErrors, setPasswordErrors] = useState("")

  useEffect(() => {
    if(messagesPerField.existsError('username')) {
      setUsernameErrors(kcMessage('invalidUsernameOrEmailMessage').props.children)
    }
    if(messagesPerField.existsError('password')) {
      setPasswordErrors(kcMessage('invalidPasswordMessage').props.children)
    }
  }, [messagesPerField, kcLanguageTag, kcMessage])

  return (
    <form id="kc-form-login" className={cx(props.kcFormClass)} action={url.loginAction} method="post">
      <Card variant="outlined">
        <CardHeader title={kcMessage("doLogIn")} />
        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <TextInput
                label={
                  !realm.loginWithEmailAllowed
                    ? kcMessage("username")
                    : (
                      realm.registrationEmailAsUsername
                        ? kcMessage("email")
                        : kcMessage("usernameOrEmail")
                    )
                }
                name="username"
                fullWidth
                required
                tabIndex={1}
                defaultValue={login.username ?? ''}
                error={!!usernameErrors}
                helperText={usernameErrors}
              />
            </Grid>
            <Grid item>
                <PasswordInput
                  label={kcMessage("password")}
                  fullWidth
                  required
                  tabIndex={2}
                  error={!!passwordErrors}
                  helperText={passwordErrors}
                  name="password"
                  autoComplete="off"

                />
            </Grid>
          </Grid>
        </CardContent>

        <CardActions sx={{mx: 1, pt: 0}}>
          <Grid container direction="column" spacing={1} alignItems="stretch">
            <LoginHelpersMenu {...kcContext} />

            <Grid item container mt={1}>
              <ActionButton
                type="submit"
                fullWidth
                tabIndex={5}
                name={"login"}
                id={"kc-login"}
                disabled={false}
              >
                {kcMessage("doLogIn")}
              </ActionButton>
            </Grid>

            <LoginSocialProviders {...kcContext} />

            {
              realm.password && realm.registrationAllowed && !registrationDisabled && <Grid item textAlign={"center"} mt={1}>
                {kcMessage("noAccount")}&nbsp;
                <Link tabIndex={6} href={url.registrationUrl}>
                  {kcMessage("doRegister")}
                </Link>
              </Grid>
            }
          </Grid>
        </CardActions>
      </Card>
    </form>
  );
});


