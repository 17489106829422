import { useEffect, memo } from "react";
import { useKcLanguageTag, kcMessages, useKcMessage, KcContextBase, KcProps } from "keycloakify";
import { Button, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import { useCssAndCx } from "tss-react";
import { ActionButton } from "@atom/ActionButton";

import tos_en_url from "@auth/terms/tos_en.md";
import tos_fr_url from "@auth/terms/tos_fr.md";

export const Terms = memo(({ kcContext, ...props }: { kcContext: KcContextBase.Terms; } & KcProps) => {
  const { kcLanguageTag } = useKcLanguageTag();
  const { msg: kcMessage, msgStr } = useKcMessage();
  const { url } = kcContext;

  const { cx } = useCssAndCx();

  useEffect(
    () => {
      if (kcContext.pageId !== "terms.ftl") {
        return;
      }

      kcMessages[kcLanguageTag].termsTitle = "";

      fetch((() => {
        switch (kcLanguageTag) {
          case "fr": return tos_fr_url;
          default: return tos_en_url;
        }
      })())
        .then(response => response.text())
        .then(rawMarkdown => kcMessages[kcLanguageTag].termsText = rawMarkdown);
    },
    [kcLanguageTag, kcContext]
  );

  return <Card>
    <CardHeader title={kcMessage("termsTitle")} />

    <CardContent sx={{ maxHeight: "512px", overflowY: "auto", overflowX: "hidden", lineBreak: "anywhere" }} className="kc-terms-text">
      {kcMessage("termsText")}
    </CardContent>

    <CardActions>
      <form className="form-actions" action={url.loginAction} method="POST" style={{ flex: 1, display: "flex", flexFlow: "row nowrap", alignItems: "center", justifyContent: "space-between" }}>
        <Button
          className={cx(
            props.kcButtonClass,
            props.kcButtonDefaultClass,
            props.kcButtonLargeClass
          )}
          name="cancel"
          id="kc-decline"
          type="submit"
        >
          { msgStr("doDecline") }
        </Button>

        <ActionButton
          className={cx(
            props.kcButtonClass,
            props.kcButtonClass,
            props.kcButtonClass,
            props.kcButtonPrimaryClass,
            props.kcButtonLargeClass
          )}
          name="accept"
          id="kc-accept"
          type="submit"
        >
          {msgStr("doAccept")}
        </ActionButton>
      </form>
    </CardActions>
  </Card>;
});
