import { Button } from "@mui/material"

export const ActionButton = ({ ...props }) => {
  return <Button
    color="secondary"
    variant="contained"
    sx={{ height: 40 }}
    {...props}
  >
    { props.children }
  </Button>
}