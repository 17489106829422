import { TextField, TextFieldProps } from "@mui/material"

export const TextInput = (props: TextFieldProps) => {
  return <TextField
    fullWidth
    label={props.label}
    size="small"
    id={props.name}
    name={props.name}
    type="text"
    error={props.error}
    helperText={props.helperText}
    {...props}
  />
}