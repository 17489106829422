import {memo, useEffect, useState} from "react";
import {KcContextBase, KcProps, useKcLanguageTag, useKcMessage} from "keycloakify";
import {useCssAndCx} from "tss-react";
import { Button, Card, CardActions, Grid, Typography, CardContent, CardHeader} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import { ActionButton } from "@atom/ActionButton";
import { TextInput } from "@molecule/form/TextInput";

export const PasswordRecovery = memo(({ kcContext, ...props }: { kcContext: KcContextBase.LoginResetPassword; } & KcProps) => {
  const {
    url,
    auth,
    realm,
    messagesPerField
  } = kcContext;

  const {msg: kcMessage} = useKcMessage();
  const { kcLanguageTag } = useKcLanguageTag();
  const {cx} = useCssAndCx();
  let [usernameErrors, setUsernameErrors] = useState("")

  useEffect(() => {
    if(messagesPerField.existsError('username')) {
      setUsernameErrors(kcMessage('invalidEmailMessage').props.children)
    }
  }, [messagesPerField, kcLanguageTag, kcMessage])

  return (
    <form id="kc-reset-password-form" className={cx(props.kcFormClass)} action={url.loginAction} method="post">
      <Card variant="outlined">
        <CardHeader title={kcMessage("emailForgotTitle")} />

        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="body2">
                {kcMessage("emailInstruction")}
              </Typography>
            </Grid>
            <Grid item>
              <TextInput
                fullWidth
                autoFocus
                required
                name="username"
                autoComplete="username"
                label={
                  !realm.loginWithEmailAllowed
                    ? kcMessage("username")
                    : !realm.registrationEmailAsUsername
                      ? kcMessage("usernameOrEmail")
                      : kcMessage("email")
                }
                defaultValue={auth?.showUsername ? auth.attemptedUsername : ""}
                error={!!usernameErrors}
                helperText={usernameErrors}
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardActions>
          <Grid container px={1}>
            <Grid item container xs={6} alignItems="center">
              <Button href={url.loginUrl}>
                {kcMessage("backToLogin")}
              </Button>
            </Grid>

            <Grid item container xs={6} justifyContent="end">
              <ActionButton type="submit" endIcon={<SendIcon />}>
                {kcMessage("doSubmit")}
              </ActionButton>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </form>
  );
});


