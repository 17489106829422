import { memo } from "react";
import { defaultKcProps as props } from "keycloakify";

import { Layout } from "@layout/Layout";
import { Login } from "@auth/login/Login";
import { PasswordRecovery } from "@auth/password-recovery/PasswordRecovery";
import { Terms } from "@auth/terms/Terms";
import { Register } from "@auth/register/Register";
import { Info } from "@auth/info/Info";
import { Error } from "@auth/error/Error";
import { VerifyEmail } from "@auth/verify-email/VerifyEmail";
import { KeycloakContext } from "@type/Keycloak";
import { LoginOtp } from "keycloakify/lib/components/LoginOtp";
import { RegisterUserProfile } from "keycloakify/lib/components/RegisterUserProfile";
import { LoginUpdateProfile } from "keycloakify/lib/components/LoginUpdateProfile";
import { LoginIdpLinkConfirm } from "keycloakify/lib/components/LoginIdpLinkConfirm";

export const KeycloakApp = memo(({ kcContext }: { kcContext: KeycloakContext }) => {
  switch (kcContext.pageId) {
    case "login.ftl": return <Layout kcContext={ kcContext }><Login {...{ kcContext, ...props }} /></Layout>;
    case "register.ftl": return <Layout kcContext={ kcContext }><Register {...{ kcContext, ...props }} /></Layout>;
    case "info.ftl": return <Layout kcContext={ kcContext }><Info {...{ kcContext, ...props }} /></Layout>;
    case "error.ftl": return <Layout kcContext={ kcContext }><Error {...{ kcContext, ...props }} /></Layout>;
    case "login-reset-password.ftl": return <Layout kcContext={ kcContext }><PasswordRecovery {...{ kcContext, ...props }} /></Layout>;
    case "login-verify-email.ftl": return <Layout kcContext={ kcContext }><VerifyEmail {...{ kcContext, ...props }} /></Layout>;
    case "terms.ftl": return <Layout kcContext={ kcContext }><Terms {...{ kcContext, ...props }} /></Layout>;
    case "login-otp.ftl": return <LoginOtp {...{ kcContext, ...props }} />;
    case "login-update-profile.ftl": return <LoginUpdateProfile {...{ kcContext, ...props }} />;
    case "login-idp-link-confirm.ftl": return <LoginIdpLinkConfirm {...{ kcContext, ...props }} />;
    case "register-user-profile.ftl": return <RegisterUserProfile {...{ kcContext, ...props }} />;
  }
});