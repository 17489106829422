import {memo, useEffect, useState} from "react";
import {KcContextBase, KcProps, useKcLanguageTag, useKcMessage} from "keycloakify";
import {Button, Card, CardActions, Grid, CardContent, CardHeader} from "@mui/material";
import { ActionButton } from "@atom/ActionButton";
import { TextInput } from "@molecule/form/TextInput";
import { PasswordInput } from "@molecule/form/PasswordInput";

export const Register = memo(({kcContext, ...props}: { kcContext: KcContextBase.Register; } & KcProps) => {
  const {
    url,
    register,
    realm,
      messagesPerField
  } = kcContext;

  const { msg: kcMessage } = useKcMessage();
  const { kcLanguageTag } = useKcLanguageTag();

  let [usernameErrors, setUsernameErrors] = useState("")
  let [passwordErrors, setPasswordErrors] = useState("")
  let [firstNameErrors, setFirstNameErrors] = useState("")
  let [lastNameErrors, setLastNameErrors] = useState("")
  let [passwordConfirmErrors, setPasswordConfirmErrors] = useState("")
  let [emailErrors, setEmailErrors] = useState("")

  let [projectId, setProjectId] = useState("")
  let [projectName, setProjectName] = useState("")


  useEffect(() => {
    if(messagesPerField.existsError('firstName')) {
      setFirstNameErrors(kcMessage('missingFirstNameMessage').props.children)
    }
    if(messagesPerField.existsError('lastName')) {
      setLastNameErrors(kcMessage('missingLastNameMessage').props.children)
    }
    if(messagesPerField.existsError('email')) {
      if(messagesPerField.get('email') === "Email already exists.") {
        setUsernameErrors(kcMessage('emailExistsMessage').props.children)
      } else {
        setUsernameErrors(kcMessage('invalidEmailMessage').props.children)
      }
    }
    if(messagesPerField.existsError('username')) {
      if(messagesPerField.get('username') === "Username already exists.") {
        setUsernameErrors(kcMessage('usernameExistsMessage').props.children)
      } else {
        setUsernameErrors(kcMessage('invalidUsernameMessage').props.children)
      }
    }
    if(messagesPerField.existsError('password')) {
      setPasswordErrors(kcMessage('invalidPasswordMessage').props.children)
    }
    if(messagesPerField.existsError('password-confirm')) {
      setPasswordConfirmErrors(kcMessage('invalidPasswordConfirmMessage').props.children)
    }
    if(messagesPerField.existsError("email")) {
      setEmailErrors(kcMessage("invalidEmailMessage").props.children)
    }
  }, [messagesPerField, kcLanguageTag, kcMessage])


  useEffect(() => {
    setProjectId(localStorage.getItem("projectId") || "")
    setProjectName(localStorage.getItem("projectName") || "")
  },[])

  return (
    <form id="kc-register-form" action={url.registrationAction} method="POST">
      <Card variant="outlined">
        <CardHeader title={ kcMessage("registerTitle") } />

        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
                <TextInput
                  label={kcMessage("firstName")}
                  required
                  name="firstName"
                  defaultValue={register.formData.firstName ?? ""}
                  error={!!firstNameErrors}
                  helperText={firstNameErrors}
                />
            </Grid>
            <Grid item>
              <TextInput
                  label={kcMessage("lastName")}
                  required
                  type="text"
                  id="lastName"
                  name="lastName"
                  defaultValue={register.formData.lastName ?? ""}
                  error={!!lastNameErrors}
                  helperText={lastNameErrors}
                />
            </Grid>
            <Grid item>
              <TextInput
                label={kcMessage("email")}
                required
                type="text"
                id="email"
                name="email"
                defaultValue={register.formData.email ?? ""}
                autoComplete="email"
                error={!!emailErrors}
                helperText={emailErrors}
              />
            </Grid>
            {
              !realm.registrationEmailAsUsername && <Grid item>
                <TextInput
                  label={kcMessage("username")}
                  name="username"
                  autoComplete="username"
                  required
                  error={!!usernameErrors}
                  helperText={usernameErrors}
                  defaultValue={register.formData.username ?? ""}
                />
              </Grid>
            }
            <Grid item>
              <PasswordInput
                label={kcMessage("password")}
                name="password"
                autoComplete="new-password"
                required
                error={!!passwordErrors}
                helperText={passwordErrors}
              />
            </Grid>
            <Grid item>
              <PasswordInput
                label={kcMessage("passwordConfirm")}
                name="password-confirm"
                autoComplete="new-password"
                required
                error={!!passwordConfirmErrors}
                helperText={passwordConfirmErrors}
              />
            </Grid>

            <TextInput
                  style={{display: "none"}}
                  id="projectId"
                  name="user.attributes.projectId"
                  defaultValue={projectId}
                  value={projectId}
                />
            <TextInput
              style={{display: "none"}}
              id="projectName"
              name="user.attributes.projectName"
              defaultValue={projectName}
              value={projectName}
            />
          </Grid>
        </CardContent>

        <CardActions>
          <Grid container spacing={2} justifyContent={"center"}>
            <Grid item justifyContent={"center"} xs={12}>
              <ActionButton fullWidth type="submit">
                {kcMessage("doRegister")}
              </ActionButton>
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth href={url.loginUrl}>
                {kcMessage("backToLogin")}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </form>
  );
});
